import * as React from 'react';
import classNames from 'classnames';
import { LinkProps } from '@wix/thunderbolt-components';
import { IImageXProps, ImageAttributesData } from '../ImageX.types';
import { TestIds } from '../constants';
import Link from '../../Link/viewer/Link';
import { replaceCompIdPlaceholder } from '../../../core/commons/vectorImageUtils';
import style from './style/ImageX.scss';

const getPictureSource = (
  sourceSets: IImageXProps['imageInfo']['sourceSets'],
  sourceSetPlaceholders?: Array<ImageAttributesData>,
) =>
  sourceSets.map((srcSet, i) => {
    const src = sourceSetPlaceholders?.[i]?.uri || undefined;
    return (
      <source key={i} media={srcSet.mediaQuery} srcSet={srcSet.src || src} />
    );
  });

const getImageLayer = (
  id: string,
  imageInfo: IImageXProps['imageInfo'],
  hasSsrSrc: string,
  defaultPlaceholder?: ImageAttributesData,
  sourceSetPlaceholders?: Array<ImageAttributesData>,
  defaultSrc?: string,
) => {
  const src = defaultPlaceholder?.uri || undefined;
  const placeholderStyle = defaultPlaceholder?.css?.img || {};

  // height is already handled - the fixed pixel size we get is messing up layout
  delete placeholderStyle.height;
  delete placeholderStyle.width;

  return (
    <wix-image
      id={`img-${id}`} // must for wix-image custom element
      data-image-info={JSON.stringify({ ...imageInfo, containerId: id })}
      data-has-ssr-src={hasSsrSrc}
    >
      <picture>
        {imageInfo.sourceSets &&
          getPictureSource(imageInfo.sourceSets, sourceSetPlaceholders)}
        <img
          src={src || defaultSrc}
          alt={imageInfo.imageData.alt}
          style={placeholderStyle}
        />
      </picture>
    </wix-image>
  );
};

const getScrollEffectLayer = (
  containerId: string,
  pageId: string,
  hasScrollEffect: boolean,
  imageLayer: React.ReactNode,
) => {
  if (hasScrollEffect) {
    return (
      <wix-bg-media
        data-page-id={pageId}
        data-container-id={containerId}
        data-use-css-vars="true"
      >
        {imageLayer}
      </wix-bg-media>
    );
  }

  return imageLayer;
};

const getLinkLayer = (
  link: LinkProps | undefined,
  showLink: boolean,
  hasScrollEffects: boolean,
  scrollEffectLayer: React.ReactNode,
) => {
  if (link && showLink) {
    return (
      <Link
        {...link}
        className={classNames({ [style.linkWithEffect]: hasScrollEffects })}
      >
        {scrollEffectLayer}
      </Link>
    );
  }

  return scrollEffectLayer;
};

const ImageX: React.FC<IImageXProps> = (props: IImageXProps) => {
  const {
    id,
    link,
    skin,
    pageId,
    showLink,
    imageInfo,
    defaultSrc,
    hasScrollEffects,
    scrollEffectStyles,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    getPlaceholder,
  } = props;
  const imagePlaceholderData = React.useRef<{
    defaultSrc: ImageAttributesData;
    sourceSet: Array<ImageAttributesData>;
  } | null>(null);
  let hasSsrSrc = '';

  if (!imagePlaceholderData.current) {
    if (getPlaceholder) {
      hasSsrSrc = 'true';

      imagePlaceholderData.current = {
        defaultSrc: getPlaceholder({
          fittingType: imageInfo.imageData.displayMode || 'fill',
          src: {
            id: imageInfo.imageData.uri,
            width: imageInfo.imageData.width,
            height: imageInfo.imageData.height,
            crop: imageInfo.imageData.crop,
            name: imageInfo.imageData.name,
            focalPoint: imageInfo.imageData.focalPoint,
          },
          target: {
            alignment: imageInfo.alignType,
            htmlTag: 'img',
          },
        }),
        sourceSet: imageInfo.sourceSets?.map(imageData =>
          getPlaceholder({
            fittingType: imageData.displayMode,
            src: {
              id: imageInfo.imageData.uri,
              width: imageInfo.imageData.width,
              height: imageInfo.imageData.height,
              crop: imageData.crop,
              name: imageInfo.imageData.name,
              focalPoint: imageData.focalPoint,
            },
            target: {
              alignment: imageInfo.alignType,
              htmlTag: 'img',
            },
          }),
        ),
      };
    } else {
      // to keep an empty placeholder data
      imagePlaceholderData.current = {
        defaultSrc: {
          uri: '',
          css: { img: {}, container: {} },
          attr: { img: {}, container: {} },
        },
        sourceSet: [],
      };
    }
  }

  const defaultPlaceholder = imagePlaceholderData.current?.defaultSrc;
  const sourceSetPlaceholders = imagePlaceholderData.current?.sourceSet;

  return (
    <div
      id={id}
      data-testid={TestIds.root}
      className={style[skin]}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <style data-testid={TestIds.scrollEffectStyle}>
        {scrollEffectStyles && replaceCompIdPlaceholder(scrollEffectStyles, id)}
      </style>
      {getLinkLayer(
        link,
        showLink,
        hasScrollEffects,
        getScrollEffectLayer(
          id,
          pageId,
          hasScrollEffects,
          getImageLayer(
            id,
            imageInfo,
            hasSsrSrc,
            defaultPlaceholder,
            sourceSetPlaceholders,
            defaultSrc,
          ),
        ),
      )}
    </div>
  );
};

export default ImageX;
