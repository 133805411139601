import ImageXComponent from '@wix/thunderbolt-elements/src/components/ImageX/viewer/ImageX';


const ImageX = {
  component: ImageXComponent
};


export const components = {
  ['ImageX']: ImageX
};


// temporary export
export const version = "1.0.0"
